/*------------------------------------------------------------------
[Color3 Stylesheet]

Project        :   Toorgo - Business Directory and classifieds premium html5 CSS template
Version        :   V.1
Create Date    :   19/02/20
Last Update    :   19/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
@import "../scss/variables";
a:hover {
	color: $color-primary;	
}
a:focus{
	text-decoration:none;
}
.pattern:before {
	background: rgba(113, 103, 255,0.92) !important;
}

.bg-background-color.bg-background-color-dark:before {
	background: rgba(113, 103, 255, 0.8) !important;
}
.bg-background2.bg-background-color-dark:before {
	background: rgba(113, 103, 255, 0.5) !important;
}
.bg-background-color {
	&.bg-background-color-dark1:before {
		background: rgba(113, 103, 255, 0.5) !important;
		border-radius: 0;
	}
	&.bg-background-color-dark2:before {
		background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(49, 45, 101, 0.3) 100%) !important;
		border-radius: 5px;
	}
}
.owl-controls .owl-page.active, .owl-controls .owl-page.active:hover {
	border-color: $color-secondary;
	opacity: 1;
	box-shadow:0px 1px 12px 4px rgba(113, 103, 255, .07);
}
.owl-nav button {
	color: $color-primary !important;
}
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
	background-color: $color-primary !important;
}
.bg-dark-purple {
	background-color: $color-dark-purple !important;
}
.btn-secondary {
	color: #fff !important;
	background-color: $color-secondary;
	border-color: $color-secondary;
	box-shadow:0px 0px 12px 0px rgba(249, 103, 104, 0.35);
}
.btn-secondary:hover {
	color: #fff;
	background-color: $btn-hover-secondary;
	border-color: $btn-hover-secondary;
}
.btn-secondary:focus, .btn-secondary.focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.5);
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.btn-secondary.disabled, .btn-secondary:disabled {
	color: #fff;
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
}
.btn-outline-secondary {
	color: $color-secondary;
	background-color: transparent;
	background-image: none;
	border-color: $color-secondary !important;
}
.btn-outline-secondary:hover {
	color: #fff;
	background-color: $color-secondary;
	border-color: $color-secondary !important;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
	color: $color-secondary;
	background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
}
.btn-link {
	color: $text-color;
}
.alert-secondary {
	color: #807575;
    background-color: #ffebeb;
    border-color: #ffebeb;
}
.alert-secondary hr {
	border-top-color: #b7cded;
}
.alert-secondary .alert-link {
	color: #fe6567;
}
.label-secondary {
	background-color: $color-secondary;
}
.badge-secondary {
	color: #fff;
	background-color: $color-secondary;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: $color-secondary;
}
.bg-secondary {
	background-color: $color-secondary !important;
}
a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
	background-color: $color-secondary !important;
}
.border-secondary {
	border-color: $color-secondary !important;
}
.text-secondary {
	color: $color-secondary !important;
}
a.text-secondary:hover, a.text-secondary:focus {
	color: $btn-hover-secondary !important;
}
.social-box.linkedin i {
	background: $color-secondary;
}
.user-card .card-block .activity-leval li.active {
	background-color: $color-secondary;
}
#count-down .clock-presenter .digit {
	background: $color-secondary;
}
.notifyimg {
	background: $color-secondary;
}
.drop-icon-wrap .drop-icon-item:hover {
	color: #605e7e;
}
.dropdown-item:hover, .dropdown-item:focus {
	color: #605e7e;
	background-color: rgba(113, 103, 255,0.085);
}
.dropdown-item.active, .dropdown-item:active {
	color: #605e7e;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: $color-primary;
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
	background-color: $color-secondary;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: $color-primary;
}
.custom-select:focus {
	border-color: $color-secondary;
}
.custom-file-input:focus~.custom-file-label::after {
	border-color: $color-primary;
}
.custom-file-label::after {
	background-color: $color-primary;
	border-left: 1px solid $color-primary;
}
.custom-range::-webkit-slider-thumb {
	background-color: $color-secondary;
}
.custom-range::-moz-range-thumb {
	background-color: $color-secondary;
}
.custom-range::-ms-thumb {
	background-color: $color-secondary;
}
.nav-link.active {
	background-color: $color-secondary;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	background-color: $color-secondary;
}
.page-link:hover {
	color: $color-secondary;
}
.page-item.active .page-link {
	background-color: $color-secondary;
    border-color: $color-secondary;
}
.range.range-secondary input[type="range"]::-webkit-slider-thumb {
	background-color: $color-secondary;
}
.range.range-secondary input[type="range"]::-moz-slider-thumb {
	background-color: $color-secondary;
}
.range.range-secondary output {
	background-color: $color-secondary;
}
.range.range-secondary input[type="range"] {
	outline-color: $color-secondary;
}
.nav-tabs .nav-item1 .nav-link:hover:not(.disabled) {
	background: $color-secondary;
}
.nav-tabs .nav-item1 .nav-link.active {
	background: $color-secondary;
}
.panel.price>.panel-heading {
	background: $color-secondary;
	color: #fff;
}
.heading-secondary {
	background-color: $color-secondary;
}
.page-header .breadcrumb-item a {
	color: #605e7e;
}
.breadcrumb-item1 a:hover {
	color: $color-secondary;
}
.panel-title1 a {
	color: $color-secondary;
}
.header-bg {
	background: #eef2f9;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #eef2f9, $color-secondary);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #eef2f9, $color-secondary);
}
.nav-tabs .nav-link:hover:not(.disabled) {
	color: #fff;
	background: $color-secondary;
}
.nav-tabs .nav-link.active {
	background: #efeff5;
}
.admin-navbar .nav-item.active .nav-link {
	color: $color-secondary;
}
.admin-navbar .nav-item.active .nav-link .icon {
	color: $color-secondary;
}
.admin-navbar .nav-link:hover, .admin-navbar .nav-link:focus {
	color: $color-secondary;
}
.admin-navbar .nav-link.active {
	color: $color-secondary;
}
.admin-navbar .mega-dropdown .sub-item .section-label {
	color: $color-secondary;
}
.admin-navbar .sub-item ul a:active {
	background-color: $color-secondary;
}
.admin-navbar .sub-item ul a:hover, .admin-navbar .sub-item ul a:focus {
	color: $color-secondary;
	text-decoration: none;
	background-color: #f9faff;
}
.expanel-secondary {
	border: $color-secondary !important;
}
.expanel-secondary>.expanel-heading {
	color: #fff !important;
	background-color: $color-secondary !important;
	border-color: $color-secondary !important;
}
.avatar {
	background: $color-secondary no-repeat center/cover;
}
.spinner {
	background: linear-gradient(120deg, $color-primary 0%, $color-primary 100%);
}
.spinner-lg {
	background-color: $color-primary;
}
.double-bounce1, .double-bounce2 {
	background: linear-gradient(120deg, $color-primary 0%, $color-primary 100%);
}
.cube1, .cube2 {
	background: linear-gradient(120deg, $color-primary 0%, $color-primary 100%);
}
.lds-heart div {
	background: $color-primary;
}
.lds-heart div:after, .lds-heart div:before {
	background: $color-primary;
}
.lds-ring div {
	border: 6px solid $color-primary;
	border-color: $color-primary transparent transparent transparent;
}
.lds-hourglass:after {
	border: 26px solid $color-primary;
	border-color: $color-primary transparent;
}
.mediaicon {
	border: 1px solid $color-primary;
	background: $color-primary;
}
a.chip:hover {
	background-color: $color-secondary;
}
.highlight .s {
	color: $color-secondary;
}
.selectgroup-input:checked+.selectgroup-button {
	z-index: 1;
    color: #676584;
    background: $border-color;
}
.selectgroup-input:focus+.selectgroup-button {
	z-index: 1;
    color: #676584;
    background:$border-color;
	box-shadow: none;
}
.custom-switch-input:checked~.custom-switch-indicator {
	background: $color-primary;
}
.custom-switch-input:focus~.custom-switch-indicator {
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.25);
	border-color: $color-primary;
}
.imagecheck-input:focus~.imagecheck-figure {
	border-color: $color-secondary;
	box-shadow: none;
}
.imagecheck-figure:before {
	background: $color-secondary url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
	color: #fff;
}
.colorinput-input:focus~.colorinput-color {
	border-color: $color-secondary;
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.ui-datepicker .ui-datepicker-title {
	color: $color-secondary;
}
#back-to-top {
	background: $color-primary;
}
#back-to-top:hover {
	background: #fff;
	color: $color-primary;
	border: 2px solid $color-primary;
}
#chat-message {
	background: rgba(113, 103, 255,0.92) !important
}
.tabs-menu ul li .active {
	border: 1px solid $color-primary;
    color: $color-primary;
}
.tabs-menu1 ul li .active {
	border: 1px solid $border-color;
    color: $color-primary;
    background: $border-color;
}
.tabs-menu1 ul li a:hover{
	border: 1px solid $border-color;
    color: $color-primary;
    background: $border-color;
}
.profile-user-tabs li a.active{
	background: $border-color;
    border: 1px solid $border-color;
    color: #605e7e;
    border-radius: 3px;
}
.wideget-user-tab .tabs-menu1 ul li .active {
	background:#fff;
	border: 1px solid $border-color;
    border-bottom: 0;
}
.rating-stars .rating-stars-container .rating-star.is--active .fa-heart, .rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
	color: $color-secondary;
}
.message-feed:not(.right) .mf-content {
	background: $color-secondary;
	color: #fff;
}
.message-feed:not(.right) .mf-content:before {
	border-right-color: $color-secondary;
}
.msb-reply button {
	background: $color-secondary;
}
.wizard-card .moving-tab {
	background-color: $color-secondary !important;
}
.wizard-card .choice:hover .icon, .wizard-card .choice.active .icon {
	border-color: $color-secondary !important;
	color: $color-secondary !important;
}
.cal1 .clndr .clndr-table .header-days {
	background: $color-secondary;
}
.cal1 .clndr .clndr-table tr .day.today.event, .cal1 .clndr .clndr-table tr .day.my-today.event {
	background: $color-secondary;
}
.cal1 .clndr .clndr-table tr .day.today:hover, .cal1 .clndr .clndr-table tr .day.my-today:hover {
	background: $color-secondary;
	color: #fff;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button {
	color: $color-secondary;
	background: #e6f2fe;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
	color: $color-secondary;
	background: #e6f2fe;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button:hover {
	background: $color-secondary;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button:hover {
	background: $color-secondary;
}
.fc button {
	background: $color-secondary;
}
.fc-event, .fc-event-dot {
	background-color: $color-secondary;
	color: #f1f1f1! important;
}
.sweet-alert button {
	background-color: $color-secondary !important;
}
.timeline__item:after {
	border: 6px solid $color-secondary;
}
.label-secondary.arrowed:before {
	border-right-color: $color-secondary;
}
.widgetdate p {
	border-bottom: 2px solid $color-secondary;
}
.widgetbox p {
	border-bottom: 2px solid $color-secondary;
}
.datebox p {
	border-bottom: 2px solid $color-secondary;
}
.arrow-ribbon.bg-secondary:before {
	border-left: 15px solid $color-secondary;
}
.arrow-ribbon2:before {
	border-left: 24px solid $color-secondary;
}
.badge-offer.bg-secondary:after {
	border-top: 12px solid $color-secondary;
}
.badge-offer1.bg-secondary:after {
	border-top: 12px solid $color-secondary;
}
.social-icons li:hover {
	background: $color-secondary;
	color: #fff;
}
.social li a:hover {
	color: $color-secondary;
}
.footer-main a:hover {
	color: $color-secondary;
}
.footer-links a:hover {
	color: $color-secondary
}
.product-tags li a:hover {
	background: $color-secondary;
	border-color: $color-secondary;
}
.info .counter-icon {
	border: 1px solid rgba(255, 255, 255, 0.6);
}
.info .counter-icon i {
	color: #fff;
}
.counter-icon {
	border: 1px solid $color-secondary;
}
.counter-icon i {
	color: $color-secondary;
}
.header-links li a.active {
	background: $color-secondary;
	color: #fff;
}
.header-links li a:hover {
	background: $color-secondary;
	color: #fff;
}
.card-pay .tabs-menu li a.active {
	background: $border-color;
}
.item1-tabs-menu ul li .active {
	background: $color-secondary;
}
.items-blog-tab-heading .items-blog-tab-menu li .active {
	background: $color-secondary;
	border:1px solid $color-secondary;
	color: #fff;
}
.item2-gl-nav .item2-gl-menu li .active {
    background: $border-color;
	border: 1px solid $border-color;
}
.item-all-cat .item-all-card:hover .item-all-text h5 {
	color: $text-color !important;
}
.item-all-cat .item-all-card {
	position: relative;
	padding: 1.5rem 1.5rem;
	border-radius: 5px;
	margin-bottom: 10px;
	color: #fff;
}
.item-all-cat .category-type .item-all-card .iteam-all-icon1 {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	padding: 1.5rem 0;
}
.item-all-cat .row .item-all-card {
	margin-bottom: 1.5rem;
}
.item-all-cat .item-all-card a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.iteam-all-icon i {
	font-size: 1.5rem
}
.item-all-card img.imag-service path {}
.item-all-card img.imag-service {
	width: 40%;
}
.carousel-indicators li.active {
	color: #fff;
	background: $color-secondary;
}
.item-cards7-ic.realestate-list li a:hover {
	color: $color-secondary;
}
.item1-links a:hover {
	color: $color-secondary;
}
.item1-links a.active {
	background: #f6f6fb;
	border-right: 2px solid $color-secondary;
}
.settings-tab .tabs-menu li a.active {
	background: $border-color;
}
.ads-tabs .tabs-menus ul li .active {
	background: $color-primary;
    color: #fff;
    border-radius: 5px;
    border: 1px solid $color-primary;
}
.showmore-button-inner {
	border: 1px solid $color-secondary;
	background:$color-secondary;
	color: #fff;
}
.owl-carousel:hover .owl-nav button {
	color: $color-primary !important;
}
.owl-nav>div i {
	color: $color-secondary;
}
.tabs-menu ul.eductaional-tabs li .active {
	background: $color-secondary;
}
.register-right .nav-tabs .nav-link:hover {
	background: #efeff5;
	color: #495057;
}
.gradient-icon {
	background: linear-gradient(120deg, #3ba2ff 0%, $color-secondary 100%);
}
.axgmap-img {
	border: 3px solid $color-secondary;
}
.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="submit"] {
	background-color: $color-secondary;
	color: #fff;
}
.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="button"] {
	background-color: $color-secondary;
	color: #fff;
}
.horizontalMenu>.horizontalMenu-list>li>a.active {
	color: #fff;
}
.horizontalMenu>.horizontalMenu-list>li:hover>a {
	color: #fff !important;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a {
	color: $color-primary;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
	color:$color-primary;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li:hover a {
	color:$color-primary;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a i {
	color: $color-primary;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a i {
	color: $color-primary;
}
code {
	color: $color-primary;
}
#gdpr-cookie-message h4 {
	color: $color-primary;
}
#gdpr-cookie-message h5 {
	color: $color-primary;
}
#gdpr-cookie-message a {
	color: $color-primary;
	border-bottom: 1px solid $color-primary;
}
#gdpr-cookie-message a:hover {
	border-bottom: 1px solid $color-primary;
	transition: all 0.3s ease-in;
	color:$color-primary;
}
button#gdpr-cookie-advanced {
	color: $text-color;
    background-color: $border-color !important;
    box-shadow: none !important;
}
.ui-widget-header {
	background: $color-secondary;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	background: $color-secondary;
}
.app-sidebar .side-menu li.active>a {
	color: $color-primary;
}
.app-sidebar .side-menu li a.active {
	color: $color-primary;
}
.app-sidebar .slide-menu li.active>a {
	color: $color-primary;
}
.app-sidebar .side-menu li ul li a:hover {
	color: $color-primary;
}
.app-sidebar .side-menu li a:hover {
	color: $color-primary;
}
.side-menu .side-menu__icon {
}
.navtab-wizard.nav-tabs .nav-link.active, .navtab-wizard.nav-tabs .nav-link:hover {
	color: #fff;
    background: $color-primary;
}
.form-inline .nav-search .btn {
	border: 2px solid $color-primary;
	font-size: 20px;
    line-height: 24px;
}
.wrapper>h1 span {
	border-bottom: 2px solid $color-secondary;
	color: $color-secondary;
}
.tab_wrapper>ul li.active {
	border-color: $color-secondary;
	background: $color-secondary;
	color: #fff;
}
.tab_wrapper .controller span {
	background: $color-secondary;
}
.tab_wrapper .content_wrapper .accordian_header.active {
	color: $color-secondary;
}
.tab_wrapper .content_wrapper .accordian_header.active:after {
	background: $color-secondary;
}
.tab_wrapper .content_wrapper .accordian_header.active .arrow {
	border-top: 3px solid $color-secondary;
	border-left: 3px solid $color-secondary;
}
.tab_wrapper.left_side>ul li.active:after {
	background: $color-secondary;
}
.tab_wrapper.right_side>ul li.active:after {
	background: $color-secondary;
}
.addui-slider .addui-slider-track .addui-slider-range {
	background: $color-secondary;
}
.addui-slider .addui-slider-track .addui-slider-handle:after {
	background: $color-secondary;
}
.accordionjs .acc_section.acc_active>.acc_head {
	background: $color-secondary;
}
.perfect-datetimepicker tbody td.selected {
	border: 1px solid $color-secondary;
	background-color: $color-secondary;
}
div.conv-form-wrapper div.options div.option {
	border: 1px solid $color-secondary;
	color: $color-secondary;
}
div.conv-form-wrapper div#messages div.message.from {
	background: $color-secondary;
}
div.conv-form-wrapper div.options div.option.selected {
	background: $color-secondary;
	color: #fff;
}
form.convFormDynamic button.submit {
	border: 1px solid $color-secondary;
	background: $color-secondary;
}
form.convFormDynamic button.submit:hover {
	background: $color-secondary;
	color: #fff;
}
.exzoom .exzoom_nav .exzoom_nav_inner span.current {
	border: 1px solid $color-secondary;
}
.exzoom .exzoom_btn a {
	/*border: 1px solid $color-secondary   ;*/
	color: $color-secondary;
}
.prev:hover {
	color: $color-secondary !important;
}
.next:hover {
	color: $color-secondary !important;
}
.g_f-s .prev:hover i {
	transform: translate(-5px, 0px);
	color: $color-secondary;
}
.g_f-s .next:hover i {
	transform: translate(5px, 0px);
	color: $color-secondary;
}
.g_f-s .close-button>*:hover {
	color: $color-secondary;
}
.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover {
	color: $color-primary;
}
.register {
	background: linear-gradient(120deg, #4f4ac7 0%, $color-secondary 100%);
}
.btnRegister {
	background: $color-secondary;
}
.register .nav-tabs .nav-link.active {
	color: $color-secondary;
	border: 1px solid $color-secondary;
}
.pretty input:checked~.state.p-secondary label:after, .pretty.p-toggle .state.p-secondary label:after {
	background-color: $color-secondary !important
}
.pretty input:checked~.state.p-secondary-o label:before, .pretty.p-toggle .state.p-secondary-o label:before {
	border-color: $color-secondary
}
.pretty input:checked~.state.p-secondary-o .icon1, .pretty input:checked~.state.p-secondary-o .svg, .pretty input:checked~.state.p-secondary-o svg, .pretty.p-toggle .state.p-secondary-o .icon1, .pretty.p-toggle .state.p-secondary-o .svg, .pretty.p-toggle .state.p-secondary-o svg {
	color: $color-secondary;
	stroke: $color-secondary
}
.pretty.p-default:not(.p-fill) input:checked~.state.p-secondary-o label:after {
	background-color: $color-secondary !important
}
.pretty.p-switch input:checked~.state.p-secondary:before {
	border-color: $color-secondary
}
.pretty.p-switch.p-fill input:checked~.state.p-secondary:before {
	background-color: $color-secondary !important;
}
.owl-carousel button.owl-dot {
	background: rgba(113, 103, 255,0.1) !important;
}
.owl-carousel button.owl-dot.active {
	background: $color-secondary !important;
}
.bg-background:before {
	background: rgba(113, 103, 255,0.92) !important;
}
.bg-background.bg-background-video:before {
	background: rgba(113, 103, 255,0.92);
}
.bg-background2:before {
	background: rgba(113, 103, 255,0.92) !important;
}
.bg-background2.white-transparent:before {
	background: rgba(0,0,0,0.5)!important;
}
.bg-background-color:before {
	background-color: rgba(113, 103, 255,0.92) !important;
}
.bg-background-color.bg-background-color-secondary:before{
	background: rgba(249, 103, 104 , 0.9) !important;
}
.bg-background3:before {
	background: rgba(49, 45, 101, 0.5)!important;
}
.bg-background-5:before {
	background: rgba(113, 103, 255,0.92) !important;
}
.bg-background-6:before {
	background: rgba(113, 103, 255,0.92) !important;
}
.slider-header:before {
	background: rgba(113, 103, 255,0.35) !important;
}
.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.custom-file-input:focus~.custom-file-label {
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.25);
	border-color: $color-primary;
}
.custom-range::-webkit-slider-thumb:focus {
	outline: none;
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.custom-range::-moz-range-thumb:focus {
	outline: none;
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.custom-range::-ms-thumb:focus {
	outline: none;
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.error-img .form-control:focus {
	outline: 0;
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.25);
	color: #fff;
	background-color: rgba(255, 255, 255, 0.2);
	border-color: $color-secondary;
}
.selectize-input.focus {
	border-color: rgb(113, 103, 255);
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.25);
}
.countdown-timer-wrapper .timer .timer-wrapper .time {}
.app-sidebar-footer {
	background: rgba(113, 103, 255,0.92);
}
.app-header-background {
	background: rgb(113, 103, 255);
}
.app-header-background.app-header-background2{
	background: rgb(113, 103, 255);
}
.app-header-background.app-header-background3{
	background: $color-dark-purple !important;
	padding:5px 0;
}
.masthead .masthead-bg {
	background: rgba(113, 103, 255,0.92) !important;
}
@media (max-width: 992px) {
	.search-show .nav-search {
		background: rgba(113, 103, 255,0.92);
	}
}
@media (max-width: 992px) {
	.nav-item.with-sub .sub-item {
		border-top: 2px solid $color-secondary;
	}
}
@media (max-width:480px) {
	.zoom-container .arrow-ribbon2:before {
		border-top: 17px solid transparent;
		border-left: 17px solid $color-secondary;
		border-bottom: 17px solid transparent;
	}
}
.border-primary {
	border-color: $color-primary !important;
}
.btn-primary {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
	box-shadow:0px 0px 12px 0px rgba(113, 103, 255, 0.35);
}
.btn-primary:hover {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.btn-primary:focus, .btn-primary.focus {
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.5);
	background-color: $color-primary;
	border-color: $color-primary;
}
.btn-primary.disabled, .btn-primary:disabled {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.5);
}

.btn-outline-primary {
	color: $color-primary;
	background-color: transparent;
	background-image: none;
	border-color: $color-primary;
	&:hover {
		color: #fff;
		background-color: $color-primary;
		border-color: $color-primary;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $color-primary;
			border-color: $color-primary;
		}
	}
}
.show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.btn-outline-primary:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.1);
	}
}
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(113, 103, 255, 0.1);
}
.bg-linkedin {
	background: #0077b5;
}
.bg-facebook {
	background: #3C5A99;
}
.accent-2 {
	border-top: 2px solid $color-secondary;
}
.header-main i {
}
.item-card .item-card-desc:before {
	background: rgba(49, 45, 101, 0.0);
}
.category-images .item-card .item-card-desc:before {
	background: rgba(49, 45, 101, 0.2);
}
.item-card.item-card2 .item-card-desc:before {
	background: rgba(49, 45, 101, 0.45);
	border-radius:5px;
}
.item-card .item-card-desc .item-card-text{
	background: linear-gradient(rgba(49, 45, 101, 0.1) 10%, rgba(49, 45, 101, 0.35) 100%);
	transition: all .5s;
}
.item-card:hover .item-card-desc .item-card-text {
	background: linear-gradient(rgba(49, 45, 101, 0.1) 10%, rgba(49, 45, 101, 0.35) 100%)!important;
}
.btn-orange {
	color: #fff;
	background-color: #e67605;
	border-color: #e67605;
}
.arrow-ribbon.bg-primary:before {
	border-left: 15px solid $color-primary;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $border-color;
}
.sticky-wrapper.relative.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
	background-color: $color-secondary;
}
.sticky-wrapper.relative.banners .horizontalMenu>.horizontalMenu-list>li:hover>a {
	background-color: $color-secondary;
}
.construction {
	z-index: 1;
}
.countdown-timer-wrapper .timer .timer-wrapper .time {
	color: $color-secondary;
	background: rgb(255, 255, 255);
}
.pricingTable:hover .pricingTable-signup {
	background: $color-secondary;
	color: #fff;
}
.pricingTable .pricingTable-signup {
	background: rgba(230, 140, 6, 0.12);
	color: $color-secondary;
}
.pricingTable:hover .title:before {
	border-right-color: $color-secondary;
}
.pricingTable:hover .title:after {
	border-left-color: $color-secondary;
}
.pricingTable .title:before, .pricingTable .title:after {
	content: "";
	border-right: 26px solid rgba(230, 140, 6, 0.1);
}
.pricingTable .title:after {
	border-right: none;
	border-left: 26px solid rgba(230, 140, 6, 0.1);
}
.page-section:before {
	background:rgba(113, 103, 255, 0.95) !important;
}
.page-section.page-section2:before {
	background:transparent !important;
}
.bg-card .cat-img i {
	color: $color-secondary;
}
.bg-card:hover .cat-img i {
	color: #fff;
}
.bg-card:hover .bg-secondary{
	background-color: #fff !important;
    color: $color-secondary !important;
}
.bg-card:hover .bg-secondary i{
	color: $color-secondary !important;
}
.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
	color: $color-primary !important;
}
.category-svg svg g, .category-svg svg, .logo-svg svg, .logo-svg svg g, .service-card-svg svg{
	fill: $color-primary;
}
.category-svg:after {
	background: rgba(113, 103, 255, 0.02);
}
.category-svg {
	background: rgba(113, 103, 255, 0.04);
}
.breadcrumb-item.active {
	color: $color-primary;
}
.perfect-datetimepicker table td.weekend {
	color: $color-secondary;
}
.invalid-feedback {
	color: $color-secondary;
}
.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
	border-color: $color-secondary;
}
.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
	border-color: $color-secondary;
	box-shadow: 0 0 0 2px rgba(205, 32, 31, .25);
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
	color: $color-secondary;
}
.custom-control-input.is-invalid~.custom-control-label, .was-validated .custom-control-input:invalid~.custom-control-label {
	color: $color-secondary;
}
.custom-file-input.is-invalid~.custom-file-label, .was-validated .custom-file-input:invalid~.custom-file-label {
	border-color: $color-secondary;
}
.horizontalMenu>.horizontalMenu-list>li .sub-menu .active{
	color: $color-primary !important;
}	
@media (min-width:992px) {
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active {
		color: $color-primary !important;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active .fa {
		color: $color-primary !important;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li:hover>a{
		color: $color-primary !important;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li:hover>a .fa{
		color: $color-primary !important;
	}
}
.sticky-wrapper.absolute.banners {
	.horizontalMenu>.horizontalMenu-list>li {
		&:hover>a .fa, >a.active .fa {
			color: $color-primary !important;
			opacity: 1;
		}
	}
}
.header-btn.has-new:before {
	background: $color-secondary;
}
.item-card7-overlaytext h4 {
	background: $color-secondary;
}
.item-card8-overlaytext h6 {
	background: $color-secondary;
}
@media (max-width:991px) {
	.horizontalMenu>.horizontalMenu-list>li>a.active {
		color: $color-primary !important;
	}
	.horizontalMenu>.horizontalMenu-list>li:hover>a{
		color: $color-primary !important;
	}
}
.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li:hover>a {
	color: $color-primary !important;
}
.ribbon-1:after {
	border-top: 13px solid $color-secondary;
}
.ribbon-1 span {
	background: $color-secondary;
}
.ribbon-1 span:before {
	background: $color-secondary;	
}
.ribbon-1 span:after {
	background: $color-secondary;
}
.pricingTable .title {
	background: rgba(233, 30, 99, .1);
	color: $color-secondary;
}
.pricingTable:hover .title {
	background: $color-secondary;
	color: #fff;
	box-shadow: 0 10px 40px 0 rgba(62, 57, 107, .2), 0 2px 9px 0 rgba(62, 57, 107, .2);
}
.pricingTable.advance-pricing {
	border: 1px solid $color-secondary;
}
.pricingTable:hover {
	border: 1px solid $color-secondary;
}
.pricingTable2.pink .pricing-plans {
	background-color: $color-secondary;
}
.bg-card-light:hover {
	box-shadow: 0 0 40px 0 rgba(152, 154, 158, 0.5);
	background: #fff;
	border-color: $color-secondary;
}
.bg-card:hover {
	box-shadow: 0 0 40px 0 rgba(152, 154, 158, 0.5);
	background: $color-secondary;
	color: #fff;
}
.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: $color-primary;
	background-color: $color-primary;
}
.bg-secondary-transparent {
	background-color: rgba(249, 103, 104, 0.1) !important;
}
.bg-primary-transparent {
	background-color: rgba(113, 103, 255, 0.1) !important;
}
.badge-primary {
	color: #fff;
	background-color: $color-primary;
}
.badge-primary[href]:focus, .badge-primary[href]:hover {
	color: #fff;
	text-decoration: none;
	background-color: $color-primary;
}
.modal-header .close.btn {
	background-color: $color-primary;
	border-color: $color-primary;
}
.heading-primary {
	background-color: $color-primary;
	color: #fff;
	padding: 5px;
}
.bg-primary {
	background-color: $color-primary !important;
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
	background-color: $color-primary !important;
}
.text-primary {
	color: $color-primary !important;
}
a.text-primary:focus, a.text-primary:hover {
	color: $color-primary !important;
}
.text-google {
	color: #dd4b39 !important;
}
a.text-google:focus, a.text-google:hover {
	color: #dd4b39 !important;
}
.text-twitter {
	color: #1da1f2 !important;
}
a.text-twitter:focus, a.text-twitter:hover {
	color: #1da1f2 !important;
}
.text-facebook {
	color: #3b5998 !important;
}
a.text-facebook:focus, a.text-facebook:hover {
	color: #3b5998 !important;
}
#gdpr-cookie-message button, button#ihavecookiesBtn {
	background: $color-primary;
	box-shadow: 0px 0px 12px 0px rgba(113, 103, 255, 0.3);
}
.ribbon-2:after {
	border-top: 13px solid $color-primary;
}
.ribbon-2 span {
	background: $color-primary;
}
.ribbon-2 span:before {
	background: $color-primary;
}
.ribbon-2 span:after {
	background: $color-primary;
}
.pricingTable2.blue .pricing-plans {
	background-color: $color-primary;
}
.pattern-1 {
	background:rgba(113, 103, 255,0.92) , url(../images/products/products/img04.jpg) center;
	border-radius: 5px;
}
.item-card2-icons a:hover{
	background:$color-secondary;
}
.header-style03 .horizontalMenu>.horizontalMenu-list>li>a.active {    
    color: $color-primary;
}
.header-style03 .horizontalMenu>.horizontalMenu-list>li>a.active>.fa {    
    color: $color-primary;
}
.header-style03 .horizontalMenu>.horizontalMenu-list>li:hover>a>.fa{
	color: $color-primary !important;
}
.header-style03 .horizontalMenu>.horizontalMenu-list>li:hover>a{
	color: $color-primary !important;
}
.item-card7-icons a:hover{
	background-color: $color-secondary;
    color: #fff;
}
.header-style-04{
	background:linear-gradient(87deg, rgba(0, 0, 0, 0.3) , rgba(0, 0, 0, 0.3) 100%) !important;
}
.xdsoft_autocomplete .xdsoft_autocomplete_dropdown>div.active {
	background:rgba(113, 103, 255, 0.1);
}
.footer-btn-outline:hover{
	border:1px solid $color-secondary;
	background-color: $color-secondary;
    color: #fff !important;
}
.label-primary {
    background-color: $color-primary;
    color: #fff;
}
.label.label-primary.arrowed-in:before, .label.label-primary.arrowed:before {
    border-right-color: $color-primary;
}
.user-tabs ul li a.active {
    background: $color-primary;
    color: #fff;
}
.alert-primary {
    color: #68667d;
    background-color: #e1dfff;
    border-color: #e1dfff;
}
.alert-primary hr {
	border-top-color: #cfd2d6;
}
.alert-primary .alert-link {
	color: $color-primary;
}
.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
	border-color:$color-primary !important
}
.sub-newsletter.d-sm-flex i{
	color: $color-secondary;
}
.place-tour-card.bg-background-color:before{
	background:rgba(49, 45, 101, .6) !important;
}
.owl-controls .owl-page.active:before{
	border-top-color: rgb(249, 103, 104);
}
#Slider .slide-carousel-indicators li.active{
	background:$color-secondary !important
}

.service-icon-timeline11  .icon-service{
	border:3px solid $color-primary;
	line-height: 114px;
}
.service-icon-timeline11  .icon-service svg{
	fill:$color-primary;
}
.service-icon-timeline12  .icon-service{
	border:3px solid $color-secondary;
	line-height: 114px;
}
.service-icon-timeline12  .icon-service svg{
	fill:$color-secondary;
}
.service-icon-timeline13  .icon-service{
	border:3px solid #00d6e6;
	line-height: 114px;
}
.service-icon-timeline13  .icon-service svg{
	fill:#00d6e6;
}
.service-icon-timeline14  .icon-service{
	border:3px solid #3eb750;
	line-height: 114px;
}
.service-icon-timeline14  .icon-service svg{
	fill:#3eb750;
}
.header-primary .horizontal-main {
    background: $color-primary;
    border-bottom: 0 !important;
}
.header-primary .sticky-wrapper.is-sticky .horizontal-main {
	border-bottom:1px solid $border-color !important;
}
.hover-state {
	color: $color-primary
}
.sw-theme-dots > ul.step-anchor > li.active > a {
    color: $color-primary;
}
.sw-theme-dots > ul.step-anchor > li.active > a:after {
    background: $color-primary;
}
.sw-theme-dots > ul.step-anchor > li > a:before {	
    color: $color-primary;
}
.flexdatalist-results li.active {
    background:$border-color;
    color: $color-primary;
    cursor: pointer;
}
li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
	background: $color-primary;
	color: #fff;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: $color-primary;
	border-color: transparent;
	color: #fff;
}
.edit-profile-icon i{	
color: #fff;
    background: $color-primary;
}

.my-dash.app-sidebar .side-menu__item.active{
   background: #fff !important;
    color: $color-primary !important;
    border-right: 2px solid $color-primary;
}
.item-card2-img:before {
	background: rgba(113, 103, 255, 0.0);
}
.absolute-title{
	background:rgba(113, 103, 255, 0.1);
}
.card-blog-img:before {
	background: rgba(113, 103, 255,0.92) !important;
}
.item-card .item-card-desc:before {
	background: rgba(113, 103, 255, 0.1);
}
@media (max-width:992px) {	
	.wideget-user-tab.wideget-user-tab3 .tab-menu-heading .nav li a.active{
		border-left: 3px solid $color-primary !important;
	}
}
*, ::after, ::before {
    box-sizing: border-box;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $color-secondary;
}
.product-slider .carousel-control-next i {
	color: $color-primary !important;
}
.product-slider .carousel-control-prev i {
	color: $color-primary !important;
}
.details-accordion .accordion>.card>.card-header{
	color: inherit;
}
.fc .fc-button-primary {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.fc .fc-button-primary:hover {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.fc .fc-button-primary:disabled {
	/* not DRY */
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
	color: #fff;
	background-color:  $color-primary;
	border-color:  $color-primary;
}
.richText .richText-editor:focus {
	border-left: $color-primary solid 2px;
}
.br-theme-bars-horizontal .br-widget a {
	background-color: $body-color;
}
.br-theme-bars-horizontal .br-widget a.br-active, .br-theme-bars-horizontal .br-widget a.br-selected {
	background-color: $color-primary;
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
	color: $color-primary;
}
.br-theme-bars-pill .br-widget a {
	background-color: $body-color;
	color: $color-primary;
}
.br-theme-bars-pill .br-widget a.br-active, .br-theme-bars-pill .br-widget a.br-selected {
	background-color: $color-primary;
	color: white;
}
.br-theme-bars-square .br-widget a {
	border: 2px solid $body-color;
	background-color: #fff;
	color: #797896;
}
.br-theme-bars-square .br-widget a.br-active, .br-theme-bars-square .br-widget a.br-selected {
	border: 2px solid $color-primary;
	color: $color-primary;
}
.br-theme-bars-movie .br-widget a {
	background-color: $body-color;
}
.br-theme-bars-movie .br-widget a.br-active, .br-theme-bars-movie .br-widget a.br-selected {
	background-color: $color-primary;
}
.br-theme-bars-movie .br-widget .br-current-rating {
	color: $color-primary;
}
.br-theme-bars-1to10 .br-widget a {
	background-color: $body-color;
}
.br-theme-bars-1to10 .br-widget a.br-active, .br-theme-bars-1to10 .br-widget a.br-selected {
	background-color: $color-primary;
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
	color: $color-primary;
}
.br-theme-fontawesome-stars-o .br-widget a.br-active:after, .br-theme-fontawesome-stars-o .br-widget a.br-selected:after {
	content: '\f005';
	color: $color-primary;
}
.br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
	content: '\f123';
	color: $color-primary;
}
.br-theme-fontawesome-stars .br-widget a:after {
	content: '\f005';
	color: $border-color;
}
.br-theme-fontawesome-stars-o .br-widget a:after {
	content: '\f006';
	color: $body-color;
}
.dark-checkboxes {
	.custom-radio .custom-control-input:checked~.custom-control-label::before {
		background-color: $color-primary;
		border: 2px solid rgba(255, 255, 255, 0.5);
	}
}
.tour-comparision {
	.card-image-difference {
		background: $color-primary;
		color: #fff;
	}
}
.app-sidebar .side-menu__item.active:before{
    background: $color-primary;
}
.slide-menu li a.slide-item.active:after{
    background: $color-primary;
}

.app-sidebar .slide-menu .slide-item.active:hover{
    color: $color-primary !important;	
}

.app-sidebar .side-menu__item.active:hover {
    color: $color-primary !important;
}

.app-sidebar__toggle i:focus, .app-sidebar__toggle i:hover {
	color:$color-primary;
	background-color:transparent;
}

.dashboard-tabs .tabs-menu ul li a {
	&.active:before {
		background: $color-primary;
	}
}
.daterangepicker {
	td {		
		&.active {
			background-color: $color-primary;
			border-color: transparent;
			color: #fff;
			&:hover {
				background-color: $color-primary;
				border-color: transparent;
				color: #fff;
			}
		}
	}
}

.tag-secondary {
	background-color: $color-secondary;
	color: #fff;
}
.price-badge {
	background: $color-secondary;
}
#chat-popup {
	background: $color-secondary;
}
.widget-spec li a{
	color: $color-primary;
}
.img-color2, .img-color3, .img-color4, .img-color5, .img-color6, .img-color7, .img-color8, .img-color9, .img-color10{
	display:none;
}